import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
const font = "/assets/fonts/1Ptvg83HX_SGhgqk3wot.woff2";

const theme = createTheme({
    palette: {
        background: { default: "#000" },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <TonConnectUIProvider
                manifestUrl={
                    "https://notcoin.nova.subhost.site/tonconnect-manifest.json"
                }
                actionsConfiguration={{
                    twaReturnUrl: "https://t.me/NotDouble2bot",
                }}
            >
                <CssBaseline />
                <App />
            </TonConnectUIProvider>
        </ThemeProvider>
    </React.StrictMode>
);
